import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortByName } from '../../../utils/sortByName';
import { fetchGroups, fetchUserSchoolYears, Group, UserSchoolYearResponse } from '../api/MyGroupsApi';
import useThrowAsyncError from '../../../hooks/useThrowAsyncError';

export type SchoolYear = {
  displayName: string;
  id: string;
} & UserSchoolYearResponse;

export type GroupsContextValue = {
  groups?: Group[];
  onGroupsChange: React.Dispatch<React.SetStateAction<Group[] | undefined>>;
  schoolYears: SchoolYear[];
  selectedSchoolYear?: SchoolYear | undefined;
  onSchoolYearChange: React.Dispatch<React.SetStateAction<SchoolYear | undefined>>;
  groupsWithSchool?: GroupsWithSchool;
};

type GroupsWithSchool = { school: Group['school']; groups: Group[] }[];

const defaultGroupsContextValue = {
  schoolYears: [],
  onSchoolYearChange: () => {},
  onGroupsChange: () => {},
};

export const GroupsContext = createContext<GroupsContextValue>(defaultGroupsContextValue);

const getTranslationKey = (schoolYear: UserSchoolYearResponse) => {
  if (schoolYear.hasPassed) return 'COMPONENTS.SCHOOLYEAR_SELECTOR.PREVIOUS';
  if (schoolYear.isCurrent) return 'COMPONENTS.SCHOOLYEAR_SELECTOR.CURRENT';
  return 'COMPONENTS.SCHOOLYEAR_SELECTOR.NEXT';
};

const sortOrder = ['unpublished', 'published', 'closed'];

const sortVOGroupsByStatus = () => (a: Group, b: Group) => {
  if (!a.status || !b.status) return 0;
  const aIndex = sortOrder.indexOf(a.status);
  const bIndex = sortOrder.indexOf(b.status);

  if (aIndex < bIndex) return -1;
  if (aIndex > bIndex) return 1;

  return 0;
};

export default function GroupsContextProvider({ children }: { children: ReactNode }) {
  const {
    i18n: { language },
  } = useTranslation();
  const [groups, setGroups] = useState<Group[]>();
  const [schoolYears, setSchoolYears] = useState<SchoolYear[]>([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SchoolYear>();

  const handleError = useThrowAsyncError();

  const groupsWithSchool = useMemo(() => {
    const unOrderedGroupsWithSchool = (groups || []).reduce<GroupsWithSchool>((acc, group) => {
      const school = acc.find(x => x.school.id === group.school.id);

      if (school) {
        school.groups.push(group);
      } else {
        acc.push({
          school: group.school,
          groups: [group],
        });
      }

      return acc;
    }, []);

    return unOrderedGroupsWithSchool.sort((a, b) =>
      a.school.name.localeCompare(b.school.name, language, { sensitivity: 'base' }),
    );
  }, [groups, language]);

  useEffect(() => {
    const getUserSchoolYears = async () => {
      const userSchoolYears = await fetchUserSchoolYears();

      const enrichedUserSchoolYears: SchoolYear[] = userSchoolYears.map(schoolYear => ({
        ...schoolYear,
        displayName: getTranslationKey(schoolYear),
        id: `${schoolYear.startYear}-${schoolYear.endYear}`,
      }));

      setSchoolYears(enrichedUserSchoolYears);
    };

    getUserSchoolYears().catch(handleError);
  }, [handleError]);

  useEffect(() => {
    if (schoolYears.length === 0) return;

    const currentSchoolYear = schoolYears.find(x => x.isCurrent);

    if (currentSchoolYear) setSelectedSchoolYear(currentSchoolYear);
  }, [schoolYears]);

  useEffect(() => {
    if (!selectedSchoolYear) return;

    const getGroupsOfSchoolYear = async () => {
      const fetchedGroups = await fetchGroups(selectedSchoolYear.id);

      const sortedGroups = fetchedGroups.sort(sortByName(language)).sort(sortVOGroupsByStatus());

      setGroups(sortedGroups);
    };

    getGroupsOfSchoolYear().catch(handleError);
  }, [selectedSchoolYear, language, handleError]);

  return (
    <GroupsContext.Provider
      value={{
        groups,
        onGroupsChange: setGroups,
        schoolYears,
        selectedSchoolYear,
        onSchoolYearChange: setSelectedSchoolYear,
        groupsWithSchool,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
}
