import List from '@pelckmans/elementis/components/list';
import { Medialink } from '../../../../modules/material/_api/MedialinksApi';
import { MaterialIcon } from './MaterialIcon';

type MaterialListProps = {
  medialinks: Medialink[];
  title: string;
  isLoading: boolean;
};

export default function MaterialList({ medialinks, title, isLoading }: MaterialListProps) {
  return (
    <List title={title} titleTag="h3" prominent isSkeleton={isLoading}>
      {medialinks.map(medialink => (
        <List.Item
          key={medialink.id}
          title={medialink.name}
          titleTag="h4"
          icon={<MaterialIcon materialType="medialink" contentType={medialink.contentType} />}
        />
      ))}
    </List>
  );
}
