import { PropsWithChildren } from 'react';
import { TableOfContentsContextProvider } from './TableOfContentsContext';
import { MedialinksContextProvider } from './MedialinksContext';

export default function TocDataProvider({ children }: PropsWithChildren) {
  return (
    <TableOfContentsContextProvider>
      <MedialinksContextProvider>{children}</MedialinksContextProvider>
    </TableOfContentsContextProvider>
  );
}
