import { MemberStatus } from '@pelckmans/business-components/types/Audiences';
import api, { ResponsePayloadWithData } from '../../../api';

export type UserSchoolYearResponse = {
  startYear: number;
  endYear: number;
  isCurrent: boolean;
  hasPassed: boolean;
};

export async function fetchUserSchoolYears(): Promise<UserSchoolYearResponse[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<UserSchoolYearResponse[]>>('/studio/user/school-years');

  return data;
}

type School = {
  id: string;
  name: string;
  cities: string;
  isSmartschool: boolean;
};

export type GroupStatus = 'unpublished' | 'published' | 'closed';

export type Group = {
  id: string;
  name: string;
  school: School;
  subjects: string[];
  schoolYear: string;
  totalAccepted: number;
  totalPending: number;
  totalDeclined: number;
  invitationId: string;
  smartschoolGroupId?: string;
  status?: GroupStatus;
  code?: string;
  startDate?: string;
  endDate?: string;
};

export type Member = {
  id: string;
  firstName?: string;
  lastName?: string;
  primaryEmail: string;
  status: MemberStatus;
  password?: string; // vo-specific
  username?: string; // vo-specific
};

// Simplified version of Group for /current/groups
export type CurrentGroup = {
  id: string;
  name: string;
  school: School;
  schoolYear: string;
  isSmartschoolSynced: boolean;
};

export type CurrentOrPreviousGroup = CurrentGroup;

export type SmartschoolGroup = Required<Group>;

export type CandidateGroup = {
  id: string;
  smartschoolGroupId: string;
  smartschoolSchoolId: string;
  name: string;
  schoolYear: string;
};

export async function fetchGroups(schoolYear: string): Promise<Group[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<Group[]>>(`studio/user/school-years/${schoolYear}/groups`, {
    params: { studioGroup: process.env.REACT_APP_P_STUDIO_GROUP },
  });

  return data;
}

export async function fetchCurrentGroups(): Promise<CurrentGroup[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<CurrentGroup[]>>('studio/user/school-years/current/groups', {
    params: { studioGroup: process.env.REACT_APP_P_STUDIO_GROUP },
  });

  return data;
}

export async function fetchCurrentAndPreviousGroups(): Promise<CurrentOrPreviousGroup[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<CurrentOrPreviousGroup[]>>(
    '/studio/user/school-years/current/previous/groups',
    { params: { studioGroup: process.env.REACT_APP_P_STUDIO_GROUP } },
  );

  return data;
}

export async function fetchMembers(schoolYear: string, groupId: string): Promise<Member[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<Member[]>>(
    `studio/user/school-years/${schoolYear}/groups/${groupId}/members`,
  );

  return data;
}

export async function fetchSmartschoolMembers(schoolYear: string, groupId: string): Promise<Member[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<Member[]>>(
    `studio/user/school-years/${schoolYear}/groups/${groupId}/smartschool-members`,
  );

  return data;
}

export async function getVOExportFile(schoolYear: string, groupId: string): Promise<Blob> {
  const { data } = await api.get(`studio/user/school-years/${schoolYear}/groups/${groupId}/members/export`, {
    responseType: 'arraybuffer',
  });

  return new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
}

export async function linkToGroup(schoolYear: string, groupId: string, email: string): Promise<Member> {
  const {
    data: { data },
  } = await api.post<ResponsePayloadWithData<Member>>(
    `studio/user/school-years/${schoolYear}/groups/${groupId}/members/link`,
    { email },
  );

  return data;
}

async function patchGroup(schoolYear: string, groupId: string, group: Partial<Group>): Promise<Group> {
  const { data } = await api.patch<Group>(`studio/user/school-years/${schoolYear}/groups/${groupId}`, group);

  return data;
}

export async function renameGroup(schoolYear: string, groupId: string, name: string): Promise<Group> {
  return patchGroup(schoolYear, groupId, { name });
}

export async function updateGroupStatus(
  schoolYear: string,
  groupId: string,
  status: GroupStatus,
  endDate?: string,
): Promise<Group> {
  return patchGroup(schoolYear, groupId, { status, ...(endDate && { endDate }) });
}

export async function removeMemberFromGroup(schoolYear: string, groupId: string, memberId: string): Promise<void> {
  await api.delete(`studio/user/school-years/${schoolYear}/groups/${groupId}/members/${memberId}`);
}

export async function getAssignmentsCountForGroup(schoolYear: string, groupId: string): Promise<number> {
  const {
    data: {
      data: { count },
    },
  } = await api.get<ResponsePayloadWithData<{ count: number }>>(
    `studio/user/school-years/${schoolYear}/groups/${groupId}/assignments/count`,
  );

  return count;
}

export async function deleteGroup(schoolYear: string, groupId: string): Promise<void> {
  await api.delete(`studio/user/school-years/${schoolYear}/groups/${groupId}`);
}

export async function fetchCandidateGroups(): Promise<CandidateGroup[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<CandidateGroup[]>>('studio/user/school-years/current/candidate-groups');

  return data;
}

export type CreateGroupRequestPayload = {
  schoolYear: string;
  name: string;
  school: {
    id: string;
    isSmartschool: boolean;
  };
  subjects: string[];
};
type CreateGroupQuery = {
  studioGroup?: StudioGroup;
};
export async function createGroup(
  { name, school, schoolYear, subjects }: CreateGroupRequestPayload,
  queryObj: CreateGroupQuery = {},
): Promise<Group> {
  const query = new URLSearchParams(queryObj);

  const { data } = await api.post<Group>(`/studio/user/school-years/${schoolYear}/groups?${query}`, {
    name,
    school,
    subjects,
  });

  return data;
}

export async function batchSmartschoolGroups(
  schoolYear: string,
  groupIds: string[],
  subjects: string[],
): Promise<SmartschoolGroup[]> {
  const {
    data: { data },
  } = await api.post<ResponsePayloadWithData<SmartschoolGroup[]>>(
    `/studio/user/school-years/${schoolYear}/batch-smartschool-groups`,
    {
      groupIds,
      subjects,
    },
  );

  return data;
}

export type GroupModule = {
  id: string;
  catalogName: string;
  years: string[];
  image?: string;
};

export type GroupModuleYearUnwind = Omit<GroupModule, 'years'> & { year: string };

function unwindYearInModule({ years, ...module }: GroupModule): GroupModuleYearUnwind {
  return {
    ...module,
    year: years[0],
  };
}

export async function fetchLinkedStudentModules(groupId: string) {
  const { data } = await api.get<GroupModule[]>(`/studio/user/groups/${groupId}/modules`);

  return data.map(unwindYearInModule);
}

export async function fetchLicensedStudentModules() {
  const query = new URLSearchParams({ years: ['1', '2'].join(',') });

  const { data } = await api.get<GroupModule[]>(`/studio/user/groups/licensed-modules?${query}`);

  return data.map(unwindYearInModule);
}

export async function linkStudentModuleToGroup(groupId: string, modulesToLink: string[]) {
  const { data } = await api.post<string[]>(`/studio/user/groups/${groupId}/modules`, modulesToLink);

  return data;
}

export type RegisterOrEditUserRequestPayload = {
  firstName: string;
  lastName: string;
  email?: string;
  username: string;
  password: string;
};

export type RegisterOrEditUserResponsePayload = RegisterOrEditUserRequestPayload & {
  id: string;
};

export async function registerStudentsInGroup(groupId: string, members: RegisterOrEditUserRequestPayload[]) {
  const {
    data: { data },
  } = await api.post<ResponsePayloadWithData<RegisterOrEditUserResponsePayload[]>>(
    `/studio/user/groups/${groupId}/members`,
    { data: members },
  );

  return data;
}

export async function editStudentInGroup(
  groupId: string,
  studentId: string,
  editInfo: RegisterOrEditUserRequestPayload,
) {
  const {
    data: { data },
  } = await api.put<ResponsePayloadWithData<RegisterOrEditUserResponsePayload[]>>(
    `/studio/user/groups/${groupId}/members/${studentId}`,
    editInfo,
  );

  return data;
}

export async function unlinkStudentModuleFromGroup(groupId: string, moduleId: string) {
  const { data } = await api.delete<string[]>(`/studio/user/groups/${groupId}/modules/${moduleId}`);
  return data;
}
