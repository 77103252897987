import { useCallback, useContext } from 'react';
import {
  UserSchoolYearResponse,
  renameGroup as renameUserGroup,
  removeMemberFromGroup,
  Member,
  deleteGroup,
} from '../api/MyGroupsApi';
import { GroupsContext } from '../contexts/GroupsContext';

export type SchoolYear = {
  displayName: string;
  id: string;
} & UserSchoolYearResponse;

export default function useGroups() {
  const { schoolYears, selectedSchoolYear, onSchoolYearChange, groups, onGroupsChange, groupsWithSchool } =
    useContext(GroupsContext);

  const renameGroup = useCallback(
    async (groupId: string, groupName: string) => {
      if (!selectedSchoolYear) return;

      const groupToRename = groups?.find(g => g.id);
      if (groupName === groupToRename?.name) return;

      const group = await renameUserGroup(selectedSchoolYear.id, groupId, groupName);

      onGroupsChange(grps => grps?.map(g => (g.id === groupId ? group : g)));
    },
    [selectedSchoolYear, groups, onGroupsChange],
  );

  const updateCounters = useCallback(
    (
      groupId: string,
      counters: {
        totalAccepted?: number;
        totalPending?: number;
        totalDeclined?: number;
      },
    ) => {
      onGroupsChange(grps =>
        grps?.map(g =>
          g.id === groupId
            ? {
                ...g,
                ...counters,
              }
            : g,
        ),
      );
    },
    [onGroupsChange],
  );

  const removeStudentFromGroup = useCallback(
    async (schoolYear: SchoolYear, groupId: string, member: Member) => {
      await removeMemberFromGroup(schoolYear.id, groupId, member.id);

      const group = groups?.find(g => g.id === groupId);

      if (group) {
        switch (member.status) {
          case 'accepted':
            updateCounters(groupId, { totalAccepted: group.totalAccepted - 1 });
            break;
          case 'pending':
            updateCounters(groupId, { totalPending: group.totalPending - 1 });
            break;
          case 'declined':
            updateCounters(groupId, { totalDeclined: group.totalDeclined - 1 });
            break;
          default:
            return;
        }
      }
    },
    [groups, updateCounters],
  );

  const removeGroup = useCallback(
    async (schoolYear: SchoolYear, groupId: string) => {
      await deleteGroup(schoolYear.id, groupId);

      onGroupsChange(groups?.filter(g => g.id !== groupId));
    },
    [groups, onGroupsChange],
  );

  return {
    schoolYears,
    selectedSchoolYear,
    onSchoolYearChange,
    groups,
    renameGroup,
    onGroupsChange,
    updateCounters,
    removeStudentFromGroup,
    removeGroup,
    groupsWithSchool,
  };
}
