import Page from '@pelckmans/elementis/components/page';
import Tabs from '@pelckmans/elementis/components/tabs';
import { useTranslation } from 'react-i18next';
import GroupsContextProvider, { SchoolYear } from './contexts/GroupsContext';
import useGroups from './hooks/useGroups';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import PageLayout from '../../layout/Page.ianua';
import OverviewMyGroups from './OverviewMyGroups.ianua';

const getSchoolYearDisplayName = (schoolYear: SchoolYear) => {
  if (schoolYear.hasPassed) return 'PAGES.MY_GROUPS.TAB_LIST.TABS.PREVIOUS';
  if (schoolYear.isCurrent) return 'PAGES.MY_GROUPS.TAB_LIST.TABS.CURRENT';
  return 'PAGES.MY_GROUPS.TAB_LIST.TABS.NEXT';
};

export function MyGroups() {
  const { t } = useTranslation();
  const { schoolYears, selectedSchoolYear, onSchoolYearChange } = useGroups();

  useDocumentTitle(t('PAGES.MY_GROUPS.PAGE_TITLE'));

  const updateSelectedSchoolYear = (schoolYearId: string) => {
    const newSchoolYear = schoolYears.find(schoolYear => schoolYear.id === schoolYearId);

    if (newSchoolYear) {
      onSchoolYearChange(newSchoolYear);
    }
  };

  return (
    <PageLayout>
      <Page>
        <Tabs selectedTab={selectedSchoolYear?.id} onTabChange={updateSelectedSchoolYear}>
          <Page.Header
            headerText={t('PAGES.MY_GROUPS.TITLE')}
            tabList={
              schoolYears.length ? (
                <Tabs.TabList aria-label={t('PAGES.MY_GROUPS.TAB_LIST.A11Y_LABEL')}>
                  {schoolYears.map(schoolYear => (
                    <Tabs.Tab key={schoolYear.id} id={schoolYear.id}>
                      {t(getSchoolYearDisplayName(schoolYear))}
                    </Tabs.Tab>
                  ))}
                </Tabs.TabList>
              ) : undefined
            }
          />
          <Page.Content>
            <Tabs.TabsContent>
              {schoolYears.map(schoolYear => (
                <Tabs.TabPanel key={schoolYear.id} id={schoolYear.id}>
                  <OverviewMyGroups schoolYear={schoolYear} />
                </Tabs.TabPanel>
              ))}
            </Tabs.TabsContent>
          </Page.Content>
        </Tabs>
      </Page>
    </PageLayout>
  );
}

export default function MyGroupsWithContext() {
  return (
    <GroupsContextProvider>
      <MyGroups />
    </GroupsContextProvider>
  );
}
