import Section from '@pelckmans/elementis/components/section';
import { useTranslation } from 'react-i18next';
import Button from '@pelckmans/elementis/components/button';
import Badge from '@pelckmans/elementis/components/badge';
import List from '@pelckmans/elementis/components/list';
import Pill from '@pelckmans/elementis/components/pill';
import SmartschoolIcon from '@pelckmans/elementis/icons/smartschool';
import PelckmansIcon from '@pelckmans/elementis/icons/pelckmans';
import ButtonGroup from '@pelckmans/elementis/components/button-group';
import useGroups, { SchoolYear } from './hooks/useGroups';

type Props = {
  schoolYear: SchoolYear;
};

function OverviewMyGroups({ schoolYear }: Props) {
  const { groupsWithSchool } = useGroups();
  const { t } = useTranslation();

  const addGroups = !schoolYear.hasPassed;

  return (
    <Section
      title={t('PAGES.MY_GROUPS.SECTION_TITLE', {
        schoolYearStartYear: schoolYear.startYear,
        schoolYearEndYear: schoolYear.endYear,
      })}
      actions={
        addGroups ? (
          <ButtonGroup aria-label={t('PAGES.MY_GROUPS.ADD_GROUP.BUTTON_GROUP.A11Y_LABEL')} role="group" size="sm">
            <Button prominence="secondary">{t('PAGES.MY_GROUPS.ADD_GROUP.BUTTON_GROUP.BUTTON')}</Button>
          </ButtonGroup>
        ) : undefined
      }
    >
      {(groupsWithSchool || []).map(({ school, groups }) => {
        return (
          <List
            titleTag="h3"
            key={school.id}
            title={[school.name, school.cities].join(', ').replace(/,\s*$/, '')}
            prominent
          >
            {groups.map(group => (
              <List.Item
                key={group.id}
                title={group.name}
                titleTag="h4"
                icon={Boolean(group.smartschoolGroupId) ? <SmartschoolIcon /> : <PelckmansIcon />}
                badgeGroupProps={{
                  role: 'group',
                  'aria-label': t('PAGES.MY_GROUPS.STUDENTS.A11Y_LABEL'),
                  children: (
                    <>
                      {group.totalAccepted > 0 && (
                        <Badge
                          prominence="success"
                          aria-label={t('PAGES.MY_GROUPS.STUDENTS.ACCEPTED.A11Y_LABEL', {
                            amount: group.totalAccepted,
                          })}
                        >
                          {group.totalAccepted}
                        </Badge>
                      )}
                      {group.totalPending > 0 && (
                        <Badge
                          prominence="warning"
                          aria-label={t('PAGES.MY_GROUPS.STUDENTS.PENDING.A11Y_LABEL', { amount: group.totalPending })}
                        >
                          {group.totalPending}
                        </Badge>
                      )}
                      {group.totalDeclined > 0 && (
                        <Badge
                          prominence="danger"
                          aria-label={t('PAGES.MY_GROUPS.STUDENTS.DECLINED.A11Y_LABEL', {
                            amount: group.totalDeclined,
                          })}
                        >
                          {group.totalDeclined}
                        </Badge>
                      )}
                    </>
                  ),
                }}
                pillGroupProps={{
                  role: 'group',
                  'aria-label': t('PAGES.MY_GROUPS.SUBJECTS.A11Y_LABEL'),
                  children: group.subjects.map(s => <Pill key={s}>{s}</Pill>),
                }}
              />
            ))}
          </List>
        );
      })}
    </Section>
  );
}

export default OverviewMyGroups;
