import Section from '@pelckmans/elementis/components/section';
import ListGroup from '@pelckmans/elementis/components/list-group';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import MaterialList from '../MaterialList';
import useTranslationRole from '../../../../../hooks/useTranslationRole';
import { useContext } from 'react';
import MedialinksContext from '../../_contexts/MedialinksContext';

export default function TeacherMaterialSection() {
  const { t } = useTranslation();
  const { role } = useTranslationRole();
  const { medialinks, isLoading } = useContext(MedialinksContext);

  const medialinksSortedByContentType = sortBy(medialinks, 'contentType.category', 'contentType.name');

  const medialinksSortedByCategory = groupBy(
    medialinksSortedByContentType,
    ({ contentType }) => contentType.category || contentType.name,
  );

  return (
    <Section title={t(`PAGES.TOC_LEVEL.SECTIONS.${role}.MATERIALS.TITLE`)}>
      <ListGroup>
        {Object.keys(medialinksSortedByCategory).map(category => (
          <MaterialList
            key={category}
            medialinks={medialinksSortedByCategory[category]}
            title={category}
            isLoading={isLoading}
          />
        ))}
      </ListGroup>
    </Section>
  );
}
